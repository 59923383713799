import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "archive-catalog-image-footprint-file-download"
    }}>{`Archive Catalog Image Footprint File Download`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`In addition to searching for individual Capella SAR data collects, you can also use the catalog service to search for and download geospatial files of the image footprints in the Capella public archive. This data is provided in bulk for the entire Capella archive for exploration and analysis in third-party geospatial tools and applications.`}</p>
    <h2 {...{
      "id": "search-for-archive-catalog-image-footprint-files"
    }}>{`Search for Archive Catalog Image Footprint Files`}</h2>
    <p>{`The archive catalog image footprint files available for download are available through the following request:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/catalog/archive-export/available
`}</code></pre>
    <p>{`which returns a response similar to the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "latest": [
        "capella_archive_export_latest.geoparquet",
        "capella_archive_export_latest.gpkg",
        "capella_archive_export_latest.shp.zip"
    ],
    "weekly": [
        "2020/43/capella_archive_export_20201019_20201026.geoparquet",
        "2020/43/capella_archive_export_20201019_20201026.gpkg",
        "2020/43/capella_archive_export_20201019_20201026.shp.zip",
        "2020/44/capella_archive_export_20201026_20201102.geoparquet",
        "2020/44/capella_archive_export_20201026_20201102.gpkg",
        "2020/44/capella_archive_export_20201026_20201102.shp.zip",
        "2020/45/capella_archive_export_20201102_20201109.geoparquet",
        "2020/45/capella_archive_export_20201102_20201109.gpkg",
        "2020/45/capella_archive_export_20201102_20201109.shp.zip",
        ...
        ... truncated for brevity
        ...
        "2023/35/capella_archive_export_20230828_20230904.geoparquet",
        "2023/35/capella_archive_export_20230828_20230904.gpkg",
        "2023/35/capella_archive_export_20230828_20230904.shp.zip",
        "2023/36/capella_archive_export_20230904_20230911.geoparquet",
        "2023/36/capella_archive_export_20230904_20230911.gpkg",
        "2023/36/capella_archive_export_20230904_20230911.shp.zip",
        "2023/37/capella_archive_export_20230911_20230918.geoparquet",
        "2023/37/capella_archive_export_20230911_20230918.gpkg",
        "2023/37/capella_archive_export_20230911_20230918.shp.zip"
    ],
    "full": [
        "capella_archive_export_full.geoparquet",
        "capella_archive_export_full.gpkg",
        "capella_archive_export_full.shp.zip"
    ]
}
`}</code></pre>
    <p>{`All archive catalog image footrpint files available for download are organized into three timescale groupings:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`latest`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`All collects captured in latest 7-day rolling period`}</li>
          <li parentName="ul">{`Updated daily at 10:00 UTC`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`weekly`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`All collects broken out by week of capture (Monday – Sunday) and listed chronologically`}</li>
          <li parentName="ul">{`Latest 4 weeks are updated daily at 10:00 UTC (to account for new data that may enter the public archive from archive holdback)`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`full`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`All collects in the public archive`}</li>
          <li parentName="ul">{`Updated weekly, Mondays at 10:00 UTC`}</li>
        </ul>
      </li>
    </ul>
    <p>{`And are provided in the following industry standard geospatial data formats:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://geoparquet.org/"
        }}>{`GeoParquet`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Apache Parquet is a powerful column-oriented data format, built from the ground up as a modern alternative to CSV files. GeoParquet is an incubating `}<a parentName="li" {...{
              "href": "https://www.ogc.org/"
            }}>{`Open Geospatial Consortium (OGC)`}</a>{` standard that adds interoperable geospatial types (Point, Line, Polygon)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.geopackage.org/"
        }}>{`GeoPackage`}</a>
        <ul parentName="li">
          <li parentName="ul">{`An open, standards-based, platform-independent, portable, self-describing, compact format for transferring geospatial information`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://doc.arcgis.com/en/arcgis-online/reference/shapefiles.htm"
        }}>{`Shapefile`}</a>{` (in a compressed zip file)`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.esri.com/en-us/home"
            }}>{`Esri`}</a>{` vector data storage format for storing the location, shape, and attributes of geographic features`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "download-archive-catalog-image-footprint-files"
    }}>{`Download Archive Catalog Image Footprint Files`}</h2>
    <p>{`The archive catalog image footrpint files can be downloaded through a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request with a properly formed message body to:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/catalog/archive-export/presigned
`}</code></pre>
    <p>{`The message body can include three parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`exportType`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Values:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`latest`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`weekly`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`full`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`Required`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`exportFormat`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`Values:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`geoparquet`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`gpkg`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`shp.zip`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`Optional`}
            <ul parentName="li">
              <li parentName="ul">{`Not to be included if `}<inlineCode parentName="li">{`fileNames`}</inlineCode>{` is specified`}</li>
              <li parentName="ul">{`Must be included if `}<inlineCode parentName="li">{`fileNames`}</inlineCode>{` is NOT specified`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`fileNames`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`List of specific file name(s) to download`}</li>
          <li parentName="ul">{`Optional`}
            <ul parentName="li">
              <li parentName="ul">{`Not to be included if `}<inlineCode parentName="li">{`exportFormat`}</inlineCode>{` is specified`}</li>
              <li parentName="ul">{`Must be included if `}<inlineCode parentName="li">{`exportFormat`}</inlineCode>{` is NOT specified`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "message-body-examples"
    }}>{`Message body examples`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Signed URL for latest data file in GeoPackage format:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/catalog/archive-export/presigned

  {
      "exportType": "latest",
      "exportFormat": "gpkg"
  }
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Signed URLs for all weekly files in GeoParquet format:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/catalog/archive-export/presigned

  {
      "exportType": "weekly",
      "exportFormat": "geoparquet"
  }
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Signed URL for full data file in Shapefile format:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/catalog/archive-export/presigned

  {
      "exportType": "full",
      "exportFormat": "shp.zip"
  }
`}</code></pre>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Signed URL for a specific weekly file in GeoPackage format:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/catalog/archive-export/presigned

  {
      "exportType": "weekly",
      "fileNames": ["2023/37/capella_archive_export_20230911_20230918.gpkg"]
  }
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "response-example-truncated"
    }}>{`Response example (truncated)`}</h3>
    <pre><code parentName="pre" {...{}}>{`    [
        {
            "exportType": "weekly",
            "exportFile": "2023/37/capella_archive_export_20230911_20230918.gpkg",
            "downloadHref": "https://capella-archive-export-prod.s3.amazonaws.com/weekly/2023/37/capella_archive_export_20230911_20230918.gpkg?AWSAccessKeyId..."
        }
    ]
`}</code></pre>
    <h3 {...{
      "id": "response-properties"
    }}>{`Response properties`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`exportType`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`The type of export, which describes the export timescale grouping`}</li>
          <li parentName="ul">{`Values`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`latest`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`weekly`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`full`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`exportFile`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`The name of the file to be downloaded`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`downloadHref`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`The signed URL to download the file`}</li>
          <li parentName="ul">{`The URL is valid for 1 hour after generation`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "file-content"
    }}>{`File content`}</h3>
    <p>{`All archive catalog image footprint files contain the following information for each collect included in the export:`}</p>
    <ul>
      <li parentName="ul">{`Collect footprint geometry`}</li>
      <li parentName="ul">{`Collect metadata`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`id`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Capella unique Collect ID`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`datetime`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Datetime the collect was acquired`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`mode`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Imaging mode of the collect`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`products`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`List of image formats available for the collect`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`url`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Link to view the collect in the Capella Console UI`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`look_angle`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Look angle - the angle between the sub-satellite point and the image center point`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`incd_angle`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Incidence angle - the angle between the radar beam and the normal to the surface of the earth`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`sqnt_angle`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Squint angle used for image collection`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`num_looks`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Number of looks used for multi-look processing`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`observ_dir`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Observation direction - whether the spacecraft is looking left or right with respect to its velocity during image acquisition`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`orbt_state`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Orbit state - whether the spacecraft is on the ascending (south to north) or descending (north to south) orbit during image acquisition`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`orbt_plane`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Orbital plane of the satellite used during image acquisition`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`polariztn`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Imaging polarization used in image acquisition`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`SLC`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the SLC format for the collect (if available)`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`GEO`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the GEO format for the collect (if available)`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`GEC`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the GEC format for the collect (if available)`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`SICD`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the SICD format for the collect (if available)`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`SIDD`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the SIDD format for the collect (if available)`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`CPHD`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`STAC ID (unique name) of the CPHD format for the collect (if available)`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on searching for and downloading archive imagery footprint files, see the API reference for the `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/catalog"
      }}>{`Catalog Service`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      